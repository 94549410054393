import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EdicaoFrotistaComponent } from './edicaoFrotista.component';

import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClienteService } from '../../service/cliente.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cadastro-frotista',
    templateUrl: './cadastroFrotista.component.html'
})

export class CadastroFrotistaComponent implements OnInit {
    frotistaForm: FormGroup;

    public mask = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];

    usuariosCorporativo: [any];

    TipoLimiteFrotista: {id: number, desc: string}[] = [
        { id: 1, desc: "Mensal" },
        { id: 2, desc: "Semanal" },
        { id: 3, desc: "Diário" }
    ];

    TipoProduto: {id: number, desc: string}[] = [
        { id: 1, desc: "Zona Azul Digital" }       
    ];

    constructor(
        private fb: FormBuilder,
        private spinner: NgxSpinnerService,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private clienteService: ClienteService,
        private translate: TranslateService) {
      
        this.frotistaForm = this.fb.group({
            cpf: ['', [Validators.required]],
            tipoLimite: ['1', []],
            limiteMaximo: ['', []],
            tipoUsuarioFrotista: [false],
            ativo: [true]
          });

          clienteService.getUsuariosFrotistaAdmin(this.clienteService.obterDocumentoOriginalAdminFrotista(), true)
            .subscribe(
                result => {                    
                    this.usuariosCorporativo = result;
                },
                fail => {                                    
                }
            );
    }
    ngOnInit() {
    }

    salvar(){
      
        if(!this.frotistaForm.valid){
          Swal.fire({
            text: this.translate.instant('RequiredFields'),
            titleText: this.translate.instant('AlertInfo'),
            icon: 'info',                 
            confirmButtonColor: '#4a9f42'
          });
          return;
        }

        if(this.frotistaForm.valid){
          //this.spinner.show();
          this.clienteService.postAdicionarUsuarioFrotista({
            DocumentoAdminEmpresaFrotista: this.clienteService.obterDocumentoOriginalAdminFrotista(),
            IdTipoLimite: this.frotistaForm.value.tipoLimite,
            LimiteMaximo: this.frotistaForm.value.limiteMaximo,
            TipoUsuarioFrotista: this.frotistaForm.value.tipoUsuarioFrotista ? 2 : 1,
            Ativo: this.frotistaForm.value.ativo,
            DocumentoUsuarioFrotista: this.frotistaForm.value.cpf.replace(/\D+/g, '')
          })
          .subscribe(
                result => {
                //this.spinner.hide();
                Swal.fire({
                    text: result.message,
                    titleText: this.translate.instant('AlertInfo'),
                    icon: 'success',                 
                    confirmButtonColor: '#4a9f42'
                  }).then((result) => {
                    if(result.value) {
                    //this.compraFinalizada.emit();
                    this.activeModal.dismiss();
                    }
                });

                },
                fail => {
                
                    //this.spinner.hide();
                    Swal.fire({
                        text: fail.message,
                        titleText: this.translate.instant('AlertInfo'),
                        icon: 'info',                 
                        confirmButtonColor: '#4a9f42'
                    });                
                    this.activeModal.dismiss();
                }
           );
        }
    }

    tipoUsuarioFrotistaChange(event){
      if(event.target.checked){
        Swal.fire({
          title: "Aviso!",
          text: "Ao alterar o usuário para administrador, todas as regras serão removidas. Deseja continuar?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#4a9f42',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Continuar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          console.log('resulvalue', result.value)
          if (result.value) {              
            this.frotistaForm.patchValue({tipoLimite: '', limiteMaximo: null});
          } else {
            event.target.checked = false;
            this.frotistaForm.patchValue({tipoUsuarioFrotista: false});
          }
        })
      }
    }

    editarUsuario(param: any){
        const modalRef = this.modalService.open(EdicaoFrotistaComponent, { centered: true, scrollable: true, size: 'sm' });
        
        modalRef.componentInstance.usuarioCorporativo = param;
        modalRef.componentInstance.setDados();
        modalRef.componentInstance.configuracaoUsuarioFrotistaAtualizada.subscribe(res => {
          this.clienteService.getUsuariosFrotistaAdmin(this.clienteService.obterDocumentoOriginalAdminFrotista(), true)
          .subscribe(
              result => {                    
                  this.usuariosCorporativo = result;
              },
              fail => {                                    
              }
          );
        });     
    }

    apagarUsuario(usuario: any){          
        
          Swal.fire({
            title: '',
            text: "Deseja mesmo excluir o Usuário Corporativo ?",
            icon: 'question',
            confirmButtonColor: '#4a9f42',
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {

                //this.spinner.show();
               
                this.clienteService.postRemoverUsuarioFrotista({
                    Documento: usuario.documentoUsuario.replace(/\D+/g, '')
                  }).subscribe(res => {

                    //this.spinner.hide();

                    const index: number = this.usuariosCorporativo.findIndex(x => x.idUsuarioCorporativo === usuario.idUsuarioCorporativo);
                    if (index !== -1) {
                      this.usuariosCorporativo.splice(index, 1);
                    }
                    Swal.fire({
                        text: 'Usuário Corporativo excluído com sucesso.',
                        titleText: this.translate.instant('AlertInfo'),
                        icon: 'success',                 
                        confirmButtonColor: '#4a9f42'
                    });
                  },
                  fail => {
                    //this.spinner.hide();
                    Swal.fire({
                      text: fail.Message,
                      titleText: 'Cadastro Frotista',
                      icon: 'info',                 
                      confirmButtonColor: '#4a9f42'
                    });
                  });               
            }
          })
    }
}