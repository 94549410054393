import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import Swal from 'sweetalert2';

import { SMSService } from '../../service/sms.service';
import { Telefone, Telefones } from 'src/app/model/telefone';
import { ClienteService } from '../../service/cliente.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-telefone-cadastro',
    templateUrl: './telefone-cadastro.component.html'
  })
  
export class TelefoneCadastroComponent implements OnInit {
    codigoTemp: string = "";
    telefoneForm: FormGroup;
    telefones: Telefone[] = [];
    telefonePost: Telefones = new Telefones();

    public maskDDD = [/[1-9]/, /[0-9]/];
    public maskPhone = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

    @Output()
    addPhone: EventEmitter<Telefone> = new EventEmitter();
    
    constructor(
        private spinner: NgxSpinnerService,
        public activeModal: NgbActiveModal
        ,private fb: FormBuilder
        ,private smsService: SMSService
        ,private clienteService: ClienteService
        ,private translate: TranslateService) {
            
    }
    
    ngOnInit() {
        this.telefoneForm = this.fb.group({
            ddd: ['', [Validators.required]],
            numero: ['', [Validators.required]],
            tipo: ['', [Validators.required]],
            isPrincipal: [true, Validators.required]
        });
    }

    salvar() {

        if(!this.telefoneForm.valid){
            Swal.fire({
                      text: this.translate.instant('RequiredFields'),
                      titleText: this.translate.instant('AlertInfo'),
                      icon: 'info',                 
                      confirmButtonColor: '#4a9f42'
                    });      
          return;
        }
       
        if(!this.telefoneSizeValido()){
            return;
        }

        if(this.telefoneForm.valid) {
             switch(this.telefoneForm.value.tipo){
                 case '0': this.enviarSmsConfirmacao();break;
                 case '1':
                 case '2': 
                    this.salvarTelefone();
                 break;
             }
        }
    }

    telefoneSizeValido(){
        switch(this.telefoneForm.value.tipo){
            case '0':
                if(this.onlyNumber(this.telefoneForm.value.numero)?.length < 8
                   || this.onlyNumber(this.telefoneForm.value.numero)?.length > 9 ){
                    Swal.fire({
                        text: this.translate.instant('CellPhoneInvalid'),
                        titleText: this.translate.instant('AlertInfo'),
                        icon: 'info',                 
                        confirmButtonColor: '#4a9f42'
                      });
                    return false;
                }
                return true;
            case '1':
            case '2':
                if(this.onlyNumber(this.telefoneForm.value.numero)?.length != 8 ){
                    Swal.fire({
                        text: this.translate.instant('PhoneNumberInvalid'),
                        titleText: this.translate.instant('AlertInfo'),
                        icon: 'info',                 
                        confirmButtonColor: '#4a9f42'
                      });
                    return false;
                }
                return true;
            default: return false;
        }
    }


    enviarSmsConfirmacao() {
        this.clienteService
            .enviarConfirmacaoSms(`55${this.onlyNumber(this.telefoneForm.value.ddd)}${this.onlyNumber(this.telefoneForm.value.numero)}`,
                this.translate.currentLang)
            .subscribe(
                resultado => {

                    if (!resultado.hasOwnProperty("jaCadastrado")) {
                        this.confirmarCodigoSms();
                        return;
                    }

                    if (resultado.hasOwnProperty("jaCadastrado") && resultado?.jaCadastrado === true)
                        Swal.fire({
                            text: this.translate.instant('MobilePhoneNumberExists'),
                            titleText: this.translate.instant('AlertInfo'),
                            icon: 'info',
                            confirmButtonColor: '#4a9f42',
                            width: 'auto'
                        });
                },
                () => { }
            );
    }

    confirmarCodigoSms(){
        Swal.fire({
            titleText: this.translate.instant('AlertInfo'),
            text: this.translate.instant('CodeConfirmCellPhone'),
            input: 'text',
            inputAttributes: {
                autocapitalize: 'on'
            },
            showCancelButton: true,
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Confirm'),
            showLoaderOnConfirm: true,
            preConfirm: (code) => {
                this.clienteService.confirmarCodigoSms(`55${this.onlyNumber(this.telefoneForm.value.ddd)}${this.onlyNumber(this.telefoneForm.value.numero)}`, code)
                    .subscribe(
                        () => {
                            this.salvarTelefone();
                            return "OK";
                        },
                        error => {
                            Swal.showValidationMessage(this.translate.instant('InvalidCodeRegisterCellPhone'));
                            return "ERROR";
                        }
                    );
                return "OK";
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
           
            if (result.value == "OK") {
                this.activeModal.close();                
            }
        });
    }

    salvarTelefone(){        

        let phone = new Telefone();
        phone.telefone = {
            tipo: this.telefoneForm.value.tipo,
            ddd: parseInt(this.onlyNumber(this.telefoneForm.value.ddd)),
            numero: parseInt(this.onlyNumber(this.telefoneForm.value.numero)),
            isPrincipal: this.telefoneForm.value.isPrincipal
        };
    
        this.telefones = [];
        this.telefones.push(phone);    
        
        this.telefonePost.telefones = [];
        this.telefonePost.telefones = this.telefones;

        this.clienteService.postTelefones(this.clienteService.obterUsuario().documento, this.telefonePost)
            .subscribe(
                (res) => {                 

                    this.addPhone.emit(phone);

                    Swal.fire({
                        text: `${this.translate.instant('PhoneNumber')} ${this._getDescricaoTipoTelefone()} ${this.translate.instant('PhoneNumberSaveSuccess')}`,
                        titleText: this.translate.instant('AlertInfo'),
                        icon: 'success',                 
                        confirmButtonColor: '#4a9f42'
                      })
                    .then(() => this.activeModal.close());
                },
                (fail) => {                    
                    //this.spinner.hide();
                }
            );
    }

    private _getDescricaoTipoTelefone(): String {
        switch(this.telefoneForm.value.tipo){
            case '0':
                return this.translate.instant('TypePhoneCell');
            case '1':
                return this.translate.instant('TypePhoneResidential');
            case '2':
                return this.translate.instant('TypePhoneCommercial');
        }
    }

    keyUpPhone(event) {
        if (this.telefoneForm.get('numero').value.replace(/\D/g, '').length >= 8 && (event.keyCode >= 48 && event.keyCode <= 57)) {
            this.maskPhone = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
        } else {
            this.maskPhone = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
        }
    }

    onlyNumber(param: string) {
        return param.replace(/\D/g, '');
    }

    makeRandom(lengthOfCode: number) {
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
        let text = "";
        for (let i = 0; i < lengthOfCode; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
          return text;
      }

      makeRandomNumber(lengthOfCode: number){
        let possible = "0123456789";
        let text = "";
        for (let i = 0; i < lengthOfCode; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
          return text;
      }
}