export const environment = {
    production: false,
    UrlInicioService: "https://gtwqa.zadsp.com.br/viprod/xBOZgBvySC1ljpifozaj/",
    UrlBase: "https://gtwqa.zadsp.com.br/viprod/xBOZgBvySC1ljpifozaj/",
    UrlClienteFull: "https://gtwqa.zadsp.com.br/",
    UrlServiceNewToken: "https://gtwqa.zadsp.com.br/broker/services/zad/clientes/",
    UrlCDN: "https://cacheappestapar.blob.core.windows.net/",
    BaseURL: "https://www.gatewayestapar.com.br/vi.qa2/OoPeRfSIATM570ClXPue/",    
    UrlCredito: "https://gtwqa.zadsp.com.br/compras/v1/",
    BaseUrlFunction: "https://apiqa.zadsp.com.br/api/",
    baseProd: "https://gtwqa.zadsp.com.br/broker/services/retaguarda/",
    timeout: 150000,
    UrlBaseBroker: ' https://gtwqa.zadsp.com.br',    
    FilialId: 38,
    ChaveCriptografia: "E$T4P4RZ0N44ZULD161T4L@",
    UrlCdn: "https://zadspqa.blob.core.windows.net",
    UrlViaCep: "https://viacep.com.br/ws/{cep}/json/",

    FuncCodeAuthSms: "Uzh93mJgaSu48dQM2kqIBJ8ZkNlH1sqXfxn9ipNROoB3AzFukVgbSQ==",
    FunctionCodeAuthorizationGeoJson: "a8YkpwohbQCaoWyAywQXrPE/raPLVzhctaaXbg/JuZcpyOy5dczJpg==",
    FuncCodeAuthAddUsuarioFrotista: "uCQjAtFIRbjxhjIUOwfiaNJAj27oRNZcVWg3YQnGt6hEAzFuyxCLyQ==",
    FuncCodeAuthRemoveUsuarioFrotista: "Y18bncl-trJfjKlwwKAh-yN3urDaJ-WGPuavMlLnjQIbAzFu3VlWxg==",  
    FuncCodeAuthBoleto: "vqSzFSIpPh6W3VIYoK3jN8T57IkV3pS1Wzi7kMbXpXJmAzFuXJA91w==",
    FuncCodeAuthUsuariosFrotAdmin: "Mn3SDtEXtBPdA-jkKbinkkUV2F8ACscf79Xwcw-wJyOIAzFuL3ielg==",
    FuncCodeAuthLeadFrotista: "5A1gexmV8SLpoHr9rKIGRteNl27MRmAlHj2cOASM_UxBAzFupt2-8Q==",
    FuncCodeTrocaSenha: "jwmUEKtRzHZzzGlVas_xfX2SBnIHSoMI6nKQsJNgdbKWAzFuAGDKNA==",
    FuncCodeSolicitaTrocaSenha: "VRHPNXZKL7sxyFEwInLPeFcW_4Ubo0rAjiJrZ3AOcD1gAzFuueE-rg==",
    FuncCodeRelatorioMovimentacoes: "J5Q9JgSAUfIhZcirPfmOQQLMZJbsh07hjdPqY4I0JbauAzFubSQW6A==",
    FuncCodeGetBoleto: "89L1wMJh0xifRH4KktItasThObJuyT7o6jHp5QoFeuKAAzFurDXVYg==",
    ZendeskKey : "b8b819f7-bcdd-4a9e-908c-abd1e869d88d",
    AssertivaKey : "brHGY64MbJ3L2EJZOr3/eceEpoP32rpRioeIrGyhJrwOCaNb3aGlWQ==",
    UrlGetBoletosBroker: "https://gatewayhml.estapar.com.br",
    RotaGetBoletosBroker: "/zadsp/boleto",
    Instituicao: 407,
    UrlGetDevice: "https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id={sessao}",
    UrlConsultaAutenticacaoCadCet : "http://appprod.cetsp.com.br/cadcon/ConsAut.aspx",
    DataInicioOperacao : "17/01/2020",
    ComerciosEspeciais: [
        { Cnpj: "04544368000171", Liberado: true }
    ],
    BrokerApplicationIdPortalCliente: "15",
    UrlValidacaoDominio: "https://validadominioqas.zadsp.com.br/AntiFraude/V1/ValidacaoDominio/",
    KeyRecaptcha: '6Lfoo5UlAAAAAEgRPY2oZJ6xT_nwuD7FE9p2vV5K',
    BaseRecaptcha: 'https://antifraudeqa.zadsp.com.br/recaptcha/api/Recaptcha'
};