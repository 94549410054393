<div class="modal-header">
    <h4 class="modal-title">{{'Business User Maintenance' | translate}}</h4>
</div>
<div class="modal-body">
    <div class="content">
        <form [formGroup]="frotistaForm">
        <div class="row">
            <div class="col-3">
                <label for="documentoCliente"><b>CPF: *</b></label>
                <input required class="form-control" [textMask]="{mask: mask}" formControlName="cpf" >
            </div>
            <div class="col-3">
                <label for="documentoCliente"><b>Tipo Limite</b></label>
                <input class="form-control" value="Sem limite -Saldo total disponível" [readonly]="true" [hidden]="!frotistaForm.get('tipoUsuarioFrotista').value">
                <select class="form-control" id="tipoLimite" formControlName="tipoLimite" [hidden]="frotistaForm.get('tipoUsuarioFrotista').value" >
                    <option value="">Sem limite -Saldo total disponível</option>
                    <option *ngFor="let tipoLimiteFrotista of TipoLimiteFrotista" value="{{tipoLimiteFrotista.id}}" [disabled]="frotistaForm.get('tipoUsuarioFrotista').value" >{{tipoLimiteFrotista.desc}}</option>
                </select>
            </div>
            <div class="col-2" [hidden]="frotistaForm.get('tipoLimite').value === ''">
                <label for="documentoCliente"><b>Limite Máximo</b></label>
                <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [required]="frotistaForm.get('tipoLimite').value !== ''" type="tel" class="form-control" formControlName="limiteMaximo" >
            </div>
            <div class="col-2">
                <label for="tipoUsuarioFrotista"><b>Admin?</b></label>
                <input type="checkbox" (click)="tipoUsuarioFrotistaChange($event)" class="form-control form-control-sm" formControlName="tipoUsuarioFrotista" >               
            </div>
            <div class="col-2">
                <label for="ativo"><b>Ativo?</b></label>
                <input type="checkbox" class="form-control form-control-sm" formControlName="ativo" >               
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 justify-content-center">
                <label for="documentoCliente form-control">&nbsp;</label>
                <button type="submit" class="btn btn-outline-success btn-block justify-content-center" (click)="salvar()">{{ 'Add User' | translate}}</button>
            </div>
        </div>
    </form>
    </div>
    <div class="row">
        <hr>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-hover">
                <thead>
                    <tr style="color: #fff; font-weight: 500;">
                        <td>CPF</td>
                        <td>Limite Máximo</td>
                        <td>Tipo do Limite</td>
                        <td>Editar</td>
                        <td>Excluir</td>
                    </tr>
                </thead>
                <tbody *ngFor="let usuario of usuariosCorporativo">
                    <tr>
                        <td style="color: #000 !important;">{{usuario.documentoUsuario | cpf}}</td>
                        <td style="color: #000 !important;">{{usuario.limite}}</td>
                        <td style="color: #000 !important;">{{usuario.tipoLimiteCorporativo}}</td>
                        <td style="color: #000 !important;">
                            <button class="btn btn-sm text-success" (click)="editarUsuario(usuario)"><i class="material-icons">edit</i></button>                            
                        </td>
                        <td style="color: #000 !important;">
                            <button class="btn btn-sm text-danger" (click)="apagarUsuario(usuario)"><i class="material-icons">delete_forever</i></button>                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{ 'Close' | translate }}</button>
</div>